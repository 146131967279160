import { UserClient, PagedListOfUserDTO, UserFilterFields, } from "@/api/nswag.generated";
export default class UserService {
    userClient = new UserClient();
    async getUsersAsync(pageSize, pageNumber, orderBy, descending, searchQuery, userFilterFields) {
        if (userFilterFields == null) {
            userFilterFields = new UserFilterFields();
        }
        const result = await this.userClient.list(userFilterFields, pageSize, pageNumber, orderBy, descending, searchQuery);
        if (result && result.users) {
            return result.users;
        }
        return new PagedListOfUserDTO();
    }
    async getUser() {
        return await this.userClient.getUser();
    }
    async getUserById(userId) {
        return await this.userClient.getUserById(userId);
    }
    async GetAvatar() {
        return await this.userClient.getAvatar();
    }
    async createUserAsync(command) {
        return await this.userClient.createUser(command);
    }
    async updateUser(command) {
        return await this.userClient.updateUser(command);
    }
    async updateUserPassword(command) {
        return await this.userClient.updateUserPassword(command);
    }
    async updateUserDetails(command) {
        return await this.userClient.updateUserDetails(command);
    }
    async editUser(command) {
        return await this.userClient.editUser(command);
    }
    async getOrganizationUsers(pageSize, pageNumber, orderBy, descending, searchQuery, organizationId, userFilterFields) {
        if (userFilterFields == null) {
            userFilterFields = new UserFilterFields();
        }
        const result = await this.userClient.listOrganizationUsers(userFilterFields, pageSize, pageNumber, orderBy, descending, searchQuery, null, organizationId);
        if (result && result.users) {
            return result.users;
        }
        return new PagedListOfUserDTO();
    }
    async resendActicationEmail(command) {
        return await this.userClient.resendActivationEmail(command);
    }
}
