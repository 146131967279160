import { i18n } from "@/main";
const t = i18n.global.t;
export default class UsersTableData {
    static getColumns() {
        return [
            //   {
            //     name: "id",
            //     hidden: true,
            //     align: "center",
            //     label: t("shared.id"),
            //     field: "id",
            //     format: (val: string): string => `${val}`,
            //     sortable: false,
            //   },
            {
                name: "firstName",
                align: "center",
                label: t("shared.firstName"),
                field: "firstName",
                format: (val) => `${val ?? ""}`,
                sortable: true,
                isBoolean: false,
                style: "width: 17,5%",
                headerStyle: "width: 17,5%",
            },
            {
                name: "lastName",
                align: "center",
                label: t("shared.lastName"),
                field: "lastName",
                format: (val) => `${val ?? ""}`,
                sortable: true,
                isBoolean: false,
                style: "width: 17,5%",
                headerStyle: "width: 17,5%",
            },
            {
                name: "email",
                align: "center",
                label: t("shared.email"),
                field: "email",
                format: (val) => `${val ?? ""}`,
                sortable: true,
                isBoolean: false,
                style: "width: 17,5%",
                headerStyle: "width: 17,5%",
            },
            {
                name: "roles",
                align: "center",
                label: t("shared.roles"),
                field: "roles",
                format: (val) => `${val !== null ? val.join(", ") : ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 17,5%",
                headerStyle: "widht: 17,5%",
            },
            {
                name: "lockoutEnabled",
                align: "center",
                label: t("shared.active"),
                field: "lockoutEnabled",
                format: (val) => `${val ?? "-"}`,
                sortable: false,
                isBoolean: true,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "emailConfirmed",
                align: "center",
                label: t("administration.emailConfirmed"),
                field: "emailConfirmed",
                format: (val) => `${val ?? "-"}`,
                sortable: false,
                isBoolean: true,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "action-details",
                align: "left",
                label: "",
                field: "action-details",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            // {
            //   name: "action-edit",
            //   align: "center",
            //   label: "",
            //   field: "action-edit",
            //   format: (val: string): string => `${val ?? ""}`,
            //   sortable: false,
            // },
            // {
            //   name: "action-delete",
            //   align: "center",
            //   label: "",
            //   field: "action-edit",
            //   format: (val: string): string => `${val ?? ""}`,
            //   sortable: false,
            // },
        ];
    }
}
